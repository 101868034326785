import {Directive, ElementRef, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ConfigServices} from './config';
import {Attachment} from './models';
import {MatSnackBar} from '@angular/material/snack-bar';

declare var $: any;

@Directive({
  selector: '[appFileUpload]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileUploadDirective),
    multi: true
  }]
})

export class FileUploadDirective implements ControlValueAccessor, OnInit {

  @Input() private file: Attachment;
  @Input() private disabled = false;

  constructor(private el: ElementRef, private configService: ConfigServices,private matSnackBar: MatSnackBar) {
  }

  public getImage(fileInput: any) {

    const that = this;
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();
      reader.onload = function(e: any) {
        that.file = that.configService.getImage(e.target.result, fileInput.target.files[0], that.file);
        if(that.file.contentType.indexOf('image') > -1
          || that.file.contentType.indexOf('pdf')> -1
          || that.file.contentType.indexOf('img')> -1
          || that.file.contentType.indexOf('csv')> -1
          || that.file.contentType.indexOf('officedocument')> -1) {
          that.propagateChange(that.file);
        }else {
          that.matSnackBar.open('Only csv/jpeg/pdf/docx files are allowed', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          that.propagateChange(undefined);
        }
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }


  ngOnInit() {

    const that = this;
    $(this.el.nativeElement).change(function(e) {

      that.getImage(e);
    });

  }

  public onChange: any = (e: any) => {
  };
  public onTouched: any = () => {
  };

  propagateChange = (_: any) => {
  };

  get value(): any {
    return this.file;
  }

  set value(v: any) {

    if (v !== this.file) {
      this.file = v;
      this.propagateChange(v);
    }
  }


  writeValue(val: Attachment): void {
    this.file = val;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
