<app-card>
  <div class="justify-content-center" *ngIf="applicationServices.application.fundingBucket ==='BURSARY'">
    <h3>NSFAS BURSARY AGREEMENT (NBA) TERMS AND CONDITIONS </h3>
    <p>Please read and accept the NBA to complete your application</p>
    <div class="divHeight" id="text">
          <p><strong> 1. INTRODUCTION<br /></strong></p>
    <p>1.1 Welcome to NSFAS. We are excited to have the opportunity to support you in your effort to successfully
      accomplish your academic goals.</p>
    <p>1.2 The NSFAS Bursary Terms and Conditions will serve as an annexure / addendum to the principle NSFAS Bursary
      Agreement, and by accepting
      these Terms and Conditions, which may be amended by NSFAS via an official NSFAS circular to your Institution of
      enrolment from time
      to time, or via sms / email or notices published on the NSFAS website or NSFAS portal, you are accepting the NSFAS
      Bursary Agreement in its totality.</p>
    <p>1.3 Please ensure that you are familiar with, and fully understand the NSFAS Bursary Agreement, including the
      Terms and Conditions.</p>
    <p>1.4 Subject to the NSFAS Eligibility Criteria and Conditions for Financial Aid which may change from time to
      time, any change in government
      policy relating to the Bursary scheme shall become applicable to the terms and conditions of this agreement.</p>
    <p>1.5 Please remember that NSFAS has a team of friendly and knowledgeable consultants who are available to discuss
      any aspect of the NSFAS
      administered DHET Bursary Scheme. Kindly contact the NSFAS Contact Centre on0800 067 327 or email
      info@nsfas.org.za.
    </p>

    <p><strong>2. WHO IS ELIGIBILE?</strong></p>
    <p>2.1 The DHET Bursary Scheme is available to South African citizens and permanent residents who meet the
      prescribed eligibility criteria.</p>
    <p>2.2 NSFAS may re-assess the eligibility of any students at any point whilst funded by NSFAS and reserves the
      right to withdraw funding if the student no longer meets the eligibility criteria.
      <span (click)="readMore()" style="color:#AE190A"><u>Read More</u></span>

    </p>

    <p><strong>2.3 ACADEMIC ELIGIBILITY</strong></p>
    <p>2.3.1 Students who have received confirmation of admission into a public higher education and training
      institution for an approved
      funded qualification and who meet the NSFAS criteria for financial assistance shall be eligible for NSFAS
      administered funding.</p>
    <p>2.3.2 Continuing students must be registered for the relevant academic cycle and must satisfy the minimum
      academic eligibility
      and progression requirements of the higher education institution and progression rules in terms of the NSFAS
      Eligibility Criteria and Conditions for Financial Aid</p>

    <p><strong>2.4 FINANCIAL ELIGIBILITY</strong></p>
    <p>2.4.1 The DHET Bursary Scheme will be available to:</p>
    <p>2.4.1.1 students who registered at a higher education institution and who are from families with a combined
      annual household income of up to R350 000 (Three Hundred and Fifty Thousand Rand);</p>
    <p>2.4.1.2 students with disabilities who registered at a higher education institution and who are from families
      with a combined annual household income of up to R600 000 (Six Hundred Thousand Rand);</p>
    <p>2.4.1.3 students who are recipients of grants administered through the South African Social Security Agency
      (“SASSA”) will automatically qualify for NSFAS funding.</p>
    <p>2.4.2 NSFAS will verify the household income declared by the student and assessed that the financial eligibility
      of the student’s household income is within the parameters set out in 2.4.1 above.</p>

    <p><strong>3. BURSARY AMOUNT</strong></p>
    <p>3.1 Successful NSFAS applicants shall be eligible for NSFAS financial aid in the form of a bursar.
    <p>3.2 The Bursary Amount per annum will be an amount equal to the actual cost of tuition for the qualification, and
      other related costs as prescribed in the NSFAS Eligibility Criteria and Conditions for Financial Aid, less any
      other
      bursaries or funding support received from other funders, as disclosed in the registration records submitted by
      the
      higher education institution.

    <p>3.3 The Bursary Amount awarded to the Student under the DHET Bursary Scheme shall be allocated to the Student in
      Bursary Packages. Each Bursary Package shall consist of the cost of tuition and residence fees (where applicable),
      and DHET determined student allowances for costs related to personal care and accommodation (inclusive of food). A
      travel allowance and a laptop allocation to participating Institutions who have opted into the Laptop Scheme, may
      also be included under the Scheme.
    <p>3.4 The student shall not be required to pay any registration fee to the Institution. The DHET determined capped
      allowances for which the student is eligible shall be made available as a credit to the student’s account by the
      higher education institution or its disbursement service provider.
    <p></p>3.5 All payments to the higher education institution shall be made in accordance with the intervals agreed
    upon
    between NSFAS and the higher education institution.
    <p></p>3.6 Any part of the Bursary Amount which has not been utilized by the higher education institution to defray
    the
    costs of tuition and related costs (as prescribed by the NSFAS Eligibility Criteria and Conditions for Financial
    Aid) shall be returned by the higher education institution to NSFAS, and the Bursary Amount will be reduced
    accordingly.
    <p></p>3.7 NSFAS will be entitled to amend the Bursary Amount.
    <p></p>3.8 NSFAS will inform the student of any amendments made in terms of clause 3.7 above. That amended amount
    will
    constitute the Bursary Amount for the purposes of this Agreement.

    <p><strong>4. REGISTRATION AND REGISTRATION FEES</strong></p>
    <p></p>4.1 Students who are NSFAS Beneficiaries shall not be required to pay registration fees. The Institution may
    not
    exclude such students on the basis of their inability to pay registration fees.
    <p></p>4.2 The Institution shall register NSFAS students for approved funded courses only. In the event that a
    student is
    eligible for NSFAS funding but is registered for a course that is not funded, NSFAS will not fund that student. It
    is the responsibility of the Institution to advise the student of this prior to the cost of study being incurred.
    <p></p>4.3 The Institution shall submit all registration data for NSFAS funded students only.

    <p><strong>5. COURSE ADJUSTMENTS (Cancellation, dropouts, deceased students)</strong></p>
    <p></p>5.1 In the event of the Student exiting the Institution on / or before 31st March of any year, for reasons
    associated with either the student dropping out, his or her death, his or her expulsion or cancelled course of
    studies, or any other reason, NSFAS must be notified of the students status by no later than the 30th June each
    year, together with all credit adjustments for these students. Students do not qualify for the full benefit of the
    NSFAS bursary in these circumstances.
    <p></p>5.2 Where a student exits between 31st March and 30th June of any year, NSFAS must be notified by the 30th
    September
    of each year, together with all credit adjustments for these students.
    <p></p>5.3 Where a student exists between 30th June and 30th September, NSFAS must be notified by the 31st December
    of each
    year, together with all credit adjustments for these students.
    <p></p>5.4 Where a student exited after the 30th September, NSFAS must be notified immediately, together with all
    credit
    adjustments for these students.
    <p></p>5.5 NSFAS will conduct quarterly adjustments, or as soon as is reasonably possible.

    <p><strong>6. ALLOWANCES</strong></p>
    <p>6.1 The allocation of allowances shall be determined in accordance with the prevailing NSFAS Eligibility Criteria
      and Conditions for Financial Aid.</p>
    <p></p>6.2 The student shall only receive those allowances which have been allocated to him/ her in accordance with
    clauses
    <p></p>6.3 The student allowances are paid in one of two ways, depending on the Institution that the student is
    attending:
    <p>6.3.1 as a direct deposit paid by the Institution, or its duly appointed agent, into the student’s bank account
      or a
      third-party vendor contracted by the institution; or
    <p>6.3.2 in the form of a NSFAS approved payment method.
    <p>6.4 Please note that:
    <p>6.4.1 the student shall be responsible for onboarding to activate his/her bank account.
    <p>6.4.2 the student shall be responsible for protecting his/her bank account details, including the bank account
      PIN
      and shall not share his/her bank account details with any person; and
    <p></p>6.4.3 the student shall immediately notify their payment method partner, where applicable, as well as NSFAS
    of any
    breach of his/her banking details.
    <p></p>6.5 The following clauses relate to allowances accessed by way of the payment method partner:
    <p></p>6.5.1 the student shall be responsible for providing the correct mobile number details to NSFAS during the
    application process. This information will be shared with the approved payment provider, where necessary.
    <p>6.5.2 the student shall be responsible for protecting his/her NSFAS Bank account details, including the account
      PIN
      and shall not share his/her account details with any person.
    <p>6.5.3 by accepting the Bursary Agreement when applying for NSFAS funding, the student shall be deemed to have
      agreed
      to the payment terms and conditions which NSFAS may amend from time to time.
    <p></p>6.5.4 subject to such terms and conditions as NSFAS deems appropriate, the student’s account will be credited
    with
    an amount equal to the allowance allocation provided for in clause 6.2 above.
    <p></p>6.5.5 any part of any allowance redeemed by the student from his/her account will reduce the amount of that
    allowance in the wallet by the amount so redeemed.
    <p></p>6.5.6 the amount reflected on the student’s account will fluctuate depending on the value of allowances
    issued and
    used from time to time.
    <p></p>6.5.7 all charges related to purchases by the student shall be for the student’s own account; and
    <p></p>6.5.8 this Agreement and the student’s usage of the NSFAS platform does not modify or replace the terms and
    conditions of any agreement concluded between the student and any other person or entity, e.g. network carriers and
    banks.

    <p></p>6.6 NSFAS shall not be held liable for any loss incurred by the student for any reason whatsoever, including
    but not
    limited to, misuse of passwords or pin codes, or the use of the student's password or pin code by a third- party.

    <p><strong>7. STUDENT OBLIGATIONS</strong></p>
    The student shall satisfy all the obligations described below in order to remain eligible for NSFAS funding:
    <p>7.1 FIRST YEAR OF STUDY
      The NSFAS funded student shall, in the first year of study and every year thereafter, comply with the following
      academic requirements:</p>
    <p>7.1.1 attend and participate in lectures, tutorials and academic support programmes as required by the higher
      education institution;</p>
    <p>7.1.2 complete assignments and/or tasks as required in terms of the due performance requirements of the higher
      education institution;</p>
    <p>7.1.3 undergo tests and examinations (written and oral) as required in terms of the due performance requirements
      of
      the higher education institution;</p>
    <p>7.1.4 must satisfy the minimum progression requirements of the higher education institution and progression rules
      in
      terms of the DHET National Rules and Guidelines;</p>
    <p>7.1.5 the student shall be required to complete the qualification within the period as prescribed by the NSFAS
      Eligibility Criteria and Conditions for Financial Aid.</p>

    <p>7.2 SECOND AND SUBSEQUENT ACADEMIC YEARS
      The NSFAS funded student shall, in the second year of study and every year thereafter, for the duration of the
      qualification participate in community service or special project ework as prescribed by the DHET National Rules
      and
      Guidelines.
    <p>7.3 SERVICE COMMITMENT</p>
    <p>7.3.1 The student hereby undertakes that he or she will remain in the country and participate in the economy in
      whichever way is most opportune for them, for at least the number of years they have benefitted from NSFAS
      funding.</p>
    <p>7.3.2 Students who wish to undertake further studies in another country may be permitted to do so, provided that
      they undertake to return to the Republic of South Africa to participate in the economy for at least the number of
      years they have benefitted from NSFAS funding.</p>
    <p>7.3.3 NSFAS funded students who wish to emigrate before the expiry of the service commitment period shall be
      required to pay back the full funds disbursed for the duration of the bursary period, before they leave the
      country.</p>
    <p>7.3.4 This amount in paragraph 7.3.3 shall be payable in one lump-sum payment or by such other manner as NSFAS
      has
      approved.</p>
    <p>7.3.5 The service commitment does not apply to students who entered higher education prior to the 2018 academic
      year.</p>

    <p><strong>8. STUDENT WARRANTIES</strong></p>
    <p>8.1 The student warrants (i.e. guarantees) that the following facts are true and complete in every aspect:</p>
    <p>8.1.1 the particulars of the student and the information recorded by the student in the accompanying Bursary
      Agreement;</p>
    <p>8.1.2 the facts stated by the student in relation to the student’s application for a bursary from NSFAS</p>
    <p>8.1.3 the student shall comply with the terms and conditions of this Agreement; and</p>
    <p>8.1.4 the student will at all times obey all the rules and codes of conduct of the higher education institution
      and
      shall pursue the qualification with commitment and dedication.</p>

    <p>8.2 The student will inform NSFAS immediately:</p>
    <p>8.2.1 if the higher education institution refuses to register or admit the student, or expels or suspends the
      student without due cause;</p>
    <p>8.2.2 if the student receives any other financial assistance in connection with the qualification;</p>
    <p>8.2.3 if the student changes the course of study during the funding period;</p>
    <p>8.2.4 the name, physical and postal addresses, telephone, fax number and e-mail address of the student’s
      employer,
      both during the course of study and after completion of the course of study; and</p>
    <p>8.2.5 any change in the information recorded by the student in the accompanying Bursary Agreement.</p>

    <p>8.3 The student will, whenever requested by NSFAS, send the following information to NSFAS in writing:</p>
    <p>8.3.1 the student’s physical residential address.</p>
    <p>8.3.2 the student’s current mobile number and e-mail address, if applicable.</p>
    <p>8.3.3 whether the student is still studying and, if so, what higher education institution the student is
      attending,
      and what qualification he or she is pursuing;</p>
    <p>8.3.4 whether the student is employed or unemployed;</p>
    <p>8.3.5 if the student is employed, the name, address and telephone number of the employer, and the total gross
      monthly remuneration which the student receives from that employer; and</p>
    <p>8.3.6 whether the student has ever been sequestrated and, if so, the details thereof.</p>

    <p><strong>9. CONSENT</strong></p>
    <p>9.1 The student and his or her parent(s), legal guardian or spouse, as the case may be, hereby consents and
      authorizes NSFAS at any time, and from time to time, without the further prior consent of the student:</p>
    <p>9.1.1 to request and obtain any academic or financial information about the student which NSFAS may require from
      the
      higher education institution or any employer, bank or other financial institution, or any person.</p>
    <p>9.1.2 to obtain consumer credit information (as defined in section 70(1) of the National Credit Act) relating to
      the
      student and/or his/her parent(s), legal guardian or spouse from any credit bureau for any purpose contemplated in
      section 70(2)(g) of that National Credit Act;</p>
    <p>9.1.3 insofar as NSFAS may by law be required to obtain the consent or authorization of the student and his or
      her
      parent(s) or legal guardian or spouse, as the case may be, to the processing of personal information, the student
      and his or her parent(s), legal guardian or spouse, as the case may be, hereby agrees and consents to:</p>
    <p>9.1.3.1 the processing of the student’s personal information as may be required to enforce or otherwise give
      effect
      to the Bursary Agreement and any other agreement or arrangement concluded between the student, NSFAS or any other
      third party contemplated herein or required to give effect to the matters contemplated in the Bursary Agreement,
      including but not limited to the processing of personal information by NSFAS and by a third-party NSFAS vendor and
      other participants under NSFAS Wallet payment platform, where applicable;</p>
    <p>9.1.3.2 the disclosure (or sharing) of personal information of the student, the student’s parent(s) or legal
      guardian and where applicable the student’s spouse, to (or with) government departments or government agencies for
      research, statistical purposes and in order to verify information provided by the student in the Application Form;
    </p>
    <p>9.1.3.3 the disclosure of personal information to, and obtaining personal information from, financial
      institutions
      in order to verify personal information of the student, the student’s parent(s) or legal guardian and where
      applicable the student’s spouse;</p>
    <p>9.1.3.4 the disclosure, to/ by the South African Revenue Services, of personal information of the student, the
    </p>
    student’s parent(s), legal guardian or spouse, any employer of the student and/or any employer of the student’s
    parent(s), legal guardian or spouse for the purposes of:
    <p>9.1.3.4.1 confirming and verifying the identity of the student and the student’s parent(s), legal guardian or
      spouse;</p>
    <p>9.1.3.4.2 confirming and verifying the identity and address of the employer of the student and the student’s
      parent(s), legal guardian or spouse;</p>
    <p>9.1.3.4.3 confirming and verifying the employment status and amount of income of</p>
    <p>9.1.3.4.4 the student and the student’s parent(s), legal guardian or spouse;</p>
    <p>9.1.3.4.5 obtaining information to assist NSFAS with the assessment of the Application Form to determine
      Financial
      Eligibility;</p>
    <p>9.1.3.4.6 tracing the student and procuring the collection or recovery of amounts owed to NSFAS in terms of loan
      agreements concluded between NSFAS and Continuing Students in 2017 or earlier.</p>
    <p>9.1.3.4.7 sharing or obtaining information in connection with any legal proceedings and</p>
    <p>9.1.3.4.8 audit and record-keeping purposes.</p>
    <p>9.1.3.5 NSFAS shall process the personal information of the student, the student’s parent(s), legal guardian, or
      spouse. Processing shall include using, storing and transferring of personal information, including the student’s
      name and contact details, for purposes of giving effect to and implementing this Agreement and facilitating the
      student’s access to NSFAS administered student financial aid. The information shall be used for all NSFAS internal
      processes related to the administration of the NSFAS administered student financial aid, research, exchange of
      data
      with other institutions including public universities and TVET colleges, Government Departments, SARS, Banks,
      Credit
      Bureaus, Attorneys, tracing agents and NSFAS service providers who assist NSFAS in the administration of the DHET
      Bursaries.</p>
    <p>9.2 In the event that the student becomes obliged to repay any amount due to NSFAS in terms of this Bursary
      Agreement, the student hereby consents and agrees to any deduction, payment or transfer from his/her bank account
      or
      salary, payable by any employer, of the amounts that may be due by the student to NSFAS under this Bursary
      Agreement
      and NSFAS is hereby authorized and empowered to do all things and make any necessary disclosures in order to give
      effect hereto. In particular, the student undertakes to confirm to any employer that he or she has consented to
      the
      deduction from the student's salary of the requisite amount which the student is obliged to pay to NSFAS pursuant
      to
      the repayment of the Bursary Amount.</p>




    <p><strong>10. PROVISION OF INFORMATION</strong></p>
    <p>10.1 The student agrees to provide NSFAS with true, accurate and complete information and documentation required
      for
      the assessment of the Application Form and for any other purpose under this Agreement.</p>
    <p>10.2 Without prejudice to any remedy which NSFAS may have in terms of this Agreement or in Law, where the
      information provided by the student is untrue and or inaccurate, NSFAS may elect to terminate this Agreement and
      withdraw the student’s access to funding.</p>
    <p>10.3 Where it can be proved that the student has intentionally submitted untrue or incorrect information to
      NSFAS,
      NSFAS may institute criminal and/or civil proceedings against the student and/or the student’s parent(s) or legal
      guardian in an effort to recover funds disbursed to the student for the period he or she had been NSFAS funded.
    </p>
    <p>10.4 Where NSFAS erroneously funds and/or incorrectly disburses amounts to a student where there is no legal
      justification for the student to be enriched, the NSFAS shall, upon becoming aware of such error, immediately
      cease
      further funding to the student and take all necessary measures, legal or otherwise, to recover such funds /
      disbursed amounts to a student.</p>

    <p><strong>11. DATA PROTECTION</strong></p>
    NSFAS undertakes to ensure that it respects and maintains the privacy and confidentiality of any personal
    information and data that it may obtain or gain access to and undertakes that it will not process any of the
    student’s personal information without his/her prior written consent.
    <p><strong>12. FORMALITIES</strong></p>
    <p>12.1. The Bursary Agreement between the student and NSFAS shall become effective (“Effective Date”). only when
      the
      following conditions are met:</p>
    <p>12.1.1 The student meets the eligibility criteria as defined in clauses 2.3 and 2.4; and</p>
    <p>12.1.2 NSFAS receives valid registration data from a public higher education institution for an approved funded
      qualification for the student.</p>
    <p>12.2 The obligations of the Parties to perform in accordance with the Bursary Agreement and Terms and Conditions
      shall be suspended until the Effective Date.</p>
    <p>12.3 It is however recorded that the failure by NSFAS to sign or otherwise formally indicate its acceptance of
      the
      terms of the Bursary Agreement, will not invalidate or otherwise affect the validity of this Bursary Agreement. It
      is understood that the submission of the Application for funding by the student and/ or any Bursary Amount
      advanced
      by NSFAS after the student’s Application submission, will constitute the acceptance of the terms of this Bursary
      Agreement and Terms and Conditions by both Parties respectively.</p>

    <p><strong>13. INDEMNITY AND LIMITATION OF LIABILITY</strong></p>
    <p>13.1 The student and the student’s parent(s), legal guardian or spouse agree/s to indemnify and hold
      NSFAS, its affiliates, board members and employees harmless in relation to any claim arising from:</p>
    <p>13.1.1 any transaction concluded by the student in respect of the purchase of goods and/ or services relating to
      the
      legality, quality, completeness, suitability or fitness for purpose of such goods and/or services;</p>
    <p>13.1.2 payments made to unintended beneficiaries;</p>
    <p>13.1.3 the use of the student’s pins or password by any third parties howsoever such parties obtained the
      student’s
      pin or password; and</p>
    <p>13.1.4 any breach of or non-compliance by the student of any of the student obligations contained in this
      Agreement
      or the rules of the higher education institution.</p>
    <p>13.2 NSFAS shall not be liable for any damages (whether direct, indirect, consequential, special or otherwise)
      arising from this Agreement or claims arising in connection with any act or omission of NSFAS, unless such act or
      omission is grossly negligent or intentionally contravenes the provisions of this Agreement.</p>
    <p>13.3 NSFAS shall not be liable in respect of any loss and/or damages incurred by the student in respect of any
      transfer or redemption of allowances made in error for any reason whatsoever. 13.4 The student indemnifies NSFAS,
      its employees and agents from any liability related to any loss suffered by the student. Accordingly, NSFAS shall
      not be responsible for any amount withdrawn from the student’s bank account, whether withdrawn by the student or
      any
      other person for any reason whatsoever.</p>

    <p><strong>14. LEGAL PROCEEDINGS</strong></p>

    <p>14.1 NSFAS may recover the Bursary Amount from the student in accordance with the clauses above.</p>
    <p>14.2 If the student does not repay the total amount of the Bursary prior to emigrating, NSFAS may institute legal
      proceedings against an emigrating student.</p>
    <p>14.3 Repayment of the Bursary amount will be subject to compound interest reckoned from the date upon which NSFAS
      claims the amount from the student.</p>
    <p>14.4 The student acknowledges that, if the student emigrates prior to contributing to the South African economy
      for
      at least the period of time that he or she benefitted from the DHET Bursary Scheme, he or she shall repay the
      total
      amount of the Bursary and any interest which may have accrued thereon. The student is required to notify NSFAS of
      his or her intention to emigrate. If the student fails to notify NSFAS of this in any manner provided for in this
      agreement:</p>
    <p>14.4.1 the student will then be in breach of this Agreement, entitling NSFAS to the various remedies contemplated
      in
      it;</p>
    <p>14.4.2 accordingly, until NSFAS becomes aware of the student’s breach, the period of time that a student is in
      default, as contemplated in that section 103(5) of the National Credit Act, the prescription will not commence;
      and</p>
    <p>14.4.3 the student will be afforded the protection of 103(5) of the National Credit Act.</p>
    <p>14.5 A certificate signed by a duly authorized officer of NSFAS, stating the fact that repayment has become due,
      and
      the amount of the Bursary and interest then outstanding, will be sufficient proof of those facts to enable legal
      proceedings (including proceedings for default judgment, summary judgment or provisional sentence) to be
      instituted
      in any Magistrate’s Court having jurisdiction at the student’s chosen service address to recover those amounts.
    </p>
    <p>14.6 The student agrees that any Magistrate’s Court having jurisdiction at the student’s chosen service address,
      will have full jurisdiction in respect of such legal proceedings, notwithstanding the fact that the amount of
      NSFAS’
      claim may exceed the normal limits of that court jurisdiction.</p>
    <p>14.7 The student will be responsible for all legal costs incurred by NSFAS, namely:</p>
    <p>14.7.1 The fees charged by NSFAS’s attorneys, on an attorney and own client basis, as agreed between the student
      and
      NSFAS, or as taxed and allowed by the Taxing Master of the Court in which the legal proceedings are brought.</p>
    <p>14.7.2 All disbursements incurred by NSFAS attorneys, as agreed or as taxed and allowed by the relevant Taxing
      Master, and</p>
    <p>14.7.3 Collection commission at the prescribed rate presently 10% (ten percent) on all amounts collected from the
      student by NSFAS’ attorneys or collection agencies.</p>

    <p><strong>15. DEFAULT AND CREDIT BUREAU</strong></p>
    <p>15.1 In the event that the student is found to be funded incorrectly and further fails to repay the total amount
      of
      the Bursary, NSFAS shall be entitled to submit adverse information concerning the student to a credit bureau.</p>
    <p>15.2 NSFAS must give the student 20 (twenty) business days written notice of NSFAS’ intention to submit adverse
      information concerning the student to the credit bureau.</p>
    <p>15.3 NSFAS will give the student 20 (twenty) working days from the date of the letter to settle all outstanding
      amounts.</p>

    <p><strong>16. BREACH AND TERMINATION</strong></p>
    <p>16.1 NSFAS is permitted to terminate this Agreement if the student does not comply with it or is found to be
      ineligible for funding.</p>
    <p>16.2 In the event of termination of this Agreement, all payments allocated to a student’s account but not yet
      used
      will become unavailable for use by the student and will be cancelled by NSFAS.</p>
    <p>16.3 NSFAS reserves the right to enforce its rights in accordance with this Agreement.</p>

    <p><strong>17. CESSION</strong></p>
    <p>The student will not be entitled to cede, assign, transfer or delegate any of his/ her rights or obligations
      under
      this Agreement without the prior written consent of NSFAS. However, NSFAS will be entitled at its absolute
      discretion and at any time to cede, assign, transfer or delegate any or all of its rights and obligations under
      this
      agreement to any natural or juristic person of its choice.</p>
    <p><strong>18. ADDRESSES FOR RECEIVING DOCUMENTS</strong></p>
    <p>18.1 Any statement or notice referred to in this agreement may be sent to the student:</p>
    <p>18.1.1 by sending it to the student email address recorded in the Bursary Agreement; or</p>
    <p>18.1.2 by sending it by SMS to the student cellphone number recorded in the Bursary Agreement.</p>
    <p>18.1.3 Written notices may be sent to the student at the residential home address, or to the relevant higher</p>
    education institution.
    <p>18.2 Notice and court proceedings may also be served on the student at these addresses.</p>
    <p>18.3 The student may deliver any notices or correspondence to NSFAS in terms of or relating to this agreement to
      the
      postal or e-mail addresses recorded in the Bursary Agreement; and</p>
    <p>18.4 The student may change his/her cellphone number or home, postal or e-mail addresses and notify NSFAS in
      accordance with the process as prescribed by NSFAS from time to time.</p>
    <p>18.5 All written notices (including legal notices and court proceedings) must be sent to NSFAS at NSFAS:
      Sixteenth
      Floor House Vincent, The Halyard, 4 Christian Barnard Cape Town City Center, Cape Town, 8000. Private Bag X1,
      Plumstead, Cape Town, 7801 (marked for the attention of the: Executive Officer).</p>

    <p><strong>19 APPLICABLE LAW AND JURISDICTION</strong></p>
    This Agreement shall in all respects be governed by and construed under the laws of the Republic of South Africa.
    <p><strong>20 MISCELLANEOUS MATTERS</strong></p>
    <p>20.1 These Terms and Conditions constitute the whole of the agreement between the Parties relating to the NSFAS
      administered DHET Bursary and, save to the extent otherwise provided herein, no undertaking, representation, term
      or
      condition relating to the subject matter of this Agreement, which is not incorporated in this Agreement, shall be
      binding on either of the Parties.</p>
    <p>20.2 Subject to the DHET Rules and Guidelines, which may change from time to time, and shall have the effect of
      overriding clauses in this agreement relating to, amongst others, financial eligibility, bursary amounts and
      allowances to mention but a few of the applicable clauses, the Student accepts this agreement in its entirety
      along
      with any future amendments to the rules and guidelines, as and when they are published by DHET.</p>
    <p>20.3 No latitude, extension of time or other indulgence shall be construed to be an implied consent or election
      by
      that Party or operate as a waiver or a novation of or otherwise affect any of its rights in terms of or arising
      from
      this Agreement.</p>

      <span (click)="readLess()" style="color:#AE190A"><u>Read Less</u></span>
    </div>

    <div class="checkbox">
      <div class="checkbox checkbox-fill">
        <input type="checkbox" name="checkbox-fill-2" id="checkbox-fill-2" name="agree" required
          [(ngModel)]="universityTC" />
        <label for="checkbox-fill-2" class="cr">I have read the NBA terms and conditions.
          <span class="text-end">
            <a href="/assets/NBATsC-27Aug2024.pdf" style="color: #AE190A" target="_blank">Download</a>
          </span></label> <br><br>
        <div class="invalid-feedback">
          Please agree to the terms and conditions
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="d-grid gap-2">
          <button class="btn btn-outline-dark btn-md mb-3" type="button" (click)="close()">Decline</button>
        </div>

      </div>
      <div class="col-md-4">
        <div class="d-grid gap-2">

          <button class="btn btn-primary btn-md mb-3" type="submit" [disabled]="!universityTC"
            (click)=" openApply()">Accept</button>
        </div>

      </div>
    </div>

  </div>

  <div class="justify-content-center" *ngIf="applicationServices.application.fundingBucket ==='LOAN'">
    <h3>LOAN TERMS and CONDITIONS</h3>
    <p>Please read and accept the Loan terms to complete your application</p>
    <h5 style="color:#AE190A">UNIVERSITY LOAN AGREEMENT TERMS AND CONDITIONS</h5>
    <div class="divHeight" id="text">
      <p><b>T’S & C’S INTRODUCTION</b></p>
      <p><b>1. LOAN AMOUNT</b></p>
      <p>1.1.1 The loan is categorized as a Developmental Credit Agreement as set out in Section 10(1) of the NCA</p>

      <p style="text-align: justify; text-justify: inter-word;">1.1.2 The loan amount recorded in the loan agreement
        will be paid as a credit to the Student account with
        the educational institution, for the academic, residence, tuition fees and other similar cost of pursuing the
        course
        of studies for which the Student is registered.</p>

      <p>1.1.3 Payment will be made to the educational institution at times agreed between NSFAS and the educational
        institution.</p>

      <p>1.1.4 The loan referred to in this document has been made for the qualification referred to in paragraph 5.1 of
        the Loan Agreement Form.</p>

      <p>1.1.5 If, at the end of that academic year, any part of the loan amount has not been used to pay fees and costs
        owed by the Student to
        the educational institution, that unused part will be returned by the educational institution to NSFAS, and the
        outstanding balance
        owed by the Student to NSFAS in terms of this agreement will be reduced accordingly.</p>

      <p>1.1.6 The loan amount is the principal debt owed by the Student and Surety to NSFAS, as contemplated in section
        101(1)(a) of the NCA.</p>

      <p>1.1.7 NSFAS reserves the right to introduce credit insurance on this principal debt.
        <span (click)="readMore()" style="color:#AE190A"><u>Read More</u></span>
      </p>

      <p>1.1.8 NSFAS reserves the right to review the financial eligibility of funded Students and terminate such
        funding
        if it finds that a Student is no longer financially eligible for continued NSFAS funding.</p>


      <h5><b>2. INTEREST</b></h5>

      <p>2.1 Whilst the Student remains studying at an educational institution, interest will remain at 0% of the loan
        amount.
        Interest will start to accrue on the Student’s individual loan account balance based on the exit date of the
        Student.
        The exit date is defined as the date the Student exits the educational institution, regardless of whether the
        Student has graduated.
        The interest will start to accrue on the outstanding capital balance after the exit date from the educational
        institution or
        when contractual repayment becomes due or payable.
      </p>
      <p> 2.2 Interest will be charged at the initial annual rate of interest recorded in the loan agreement.</p>
      <p style="padding-left: 1%;">2.2.1 Thereafter the interest rate will be the variable rate fixed annually with the
        prime lending rate as the reference rate,
        i.e. Prime lending rate effective as at 1 April each year less 100 basis points, and that interest rate will
        apply for the next twelve (12) months, until 31 March of the following year.</p>

      <p style="padding-left: 1%;">2.2.2 The NSFAS interest rate is calculated at a hundred percent (100%) of the
        Repurchase (Repo) Rate as at 1 April of each year.</p>

      <p style="padding-left: 1%;">2.2.3 The Repo Rate is the Repurchase Rate and subject to change as determined from
        time to
        time by the Monetary Policy Committee of the South African Reserve Bank.</p>

      <p style="padding-left: 1%;">2.2.4 NSFAS must give the Student written notice of any new interest rate not later
        than thirty (30) working days
        after the day on which the new rate takes effect. This notification may be sent by NSFAS to the Student via
        email and/or sms.</p>

      <p>
        2.3 If the Student fails to make repayments in terms of this agreement, interest will continue to be charged on
        arrears or
        unpaid amounts at the rate contemplated in paragraph 2.2 above. No increased or penalty interest will be
        charged.
      </p>

      <p><b>3. REPAYMENT</b></p>

      <p>The Student and Surety must begin repaying the loan amount and accrued interest in monthly repayments as soon
        as the Student finds employment.</p>

      <p>3.1 The amount of each repayments will be set down:</p>
      <p style="padding-left: 1%;">3.1.1 In the scales prescribed by the Minister of Education in terms of section 27 of
        Act 56 of 1999 (The NSFAS Act), or if there are no such scales,</p>
      <p style="padding-left: 1%;">3.1.2 In scales drawn up by NSFAS from time to time.</p>

      <p>3.2 The scales prescribed by the Minister or drawn up by NSFAS will set down a sliding scale of repayments , on
        the basis that:</p>

      <p style="padding-left: 1%;">3.2.1 The higher the Student total annual income, the higher the monthly repayments.
      </p>
      <p style="padding-left: 1%;">3.2.2 While the Student total annual income is below the minimum level set by NSFAS
      </p>
      <p style="padding-left: 2%;">3.2.2.1 The Student and Surety will not have to pay any repayments..</p>
      <p style="padding-left: 2%;">3.2.2.2 Interest will, however, continue to be charged on the outstanding balance.
      </p>

      <p>3.4 While the Student annual income is at or above the minimum level referred to above, the Student and Surety
        must pay the applicable monthly repayments to NSFAS, until the loan amount and all interest has been paid.</p>

      <p>3.5 Each monthly repayments will be a separate debt and will become due (as contemplated in section 12(1) of
        the Prescription Act, No. 68 of 1969) only on the last day of the month in which NSFAS is entitled to demand
        payment of that repayments.</p>
      <p>3.4 While the Student annual income is at or above the minimum level referred to above, the Student and
        Surety must pay the applicable monthly repayments to NSFAS, until the loan amount and all interest has been
        paid.</p>

      <p>3.5 Each monthly repayments will be a separate debt and will become due (as contemplated in section 12(1)
        of the Prescription Act, No. 68 of 1969) only on the last day of the month in which NSFAS is entitled to demand
        payment of that repayments.
      </p>

      <p>3.6 Extinctive prescription will not run in respect of the outstanding balance of the loan amount or any
        accrued interest:</p>
      <p style="padding-left: 1%;">3.6.1 During any period when the Student and Surety are not paying repayments because
        the Student annual income is below the minimum level.</p>
      <p style="padding-left: 1%;">3.6.2 For as long as the Student fails to notify NSFAS in writing that the Student
        annual income has increased/decreased to or beyond
        that minimum level. Any such failure will be deemed to be a willful act or omission preventing NSFAS from coming
        to know of the
        existence of a debt, as contemplated in section 12(2) of the Prescription Act.</p>
      <p style="padding-left: 1%;">3.6.3 Unless NSFAS enforces any right it may acquire to demand early repayment in
        terms of paragraph 4.</p>

      <p>3.7 All repayment received may be applied by NSFAS firstly to any legal costs incurred in recovering those
        repayments,
        then to accrued interest, and finally to the outstanding balance of the loan amount.

      <p>3.8 All repayments must be made in South African currency.</p>


      <p><b>4. NSFAS CAN DEMAND EARLY REPAYMENT</b></p>

      <p>NSFAS will be entitled to withhold payment of any or all amounts to the educational institution, and/or to
        demand,
        by written notice to the Student, that the Student and Surety must repay the whole unpaid balance of the
        loan amount and accrued interest immediately, in one lump sum, if:
      </p>


      <p>4.1 The Student makes any dishonest or materially inaccurate statement in his/her application for a NSFAS loan.
      </p>
      <p>4.2 The educational institution, for whatever reason, refuses to register or admit the Student, or suspends or
        expels the Student.</p>
      <p>4.3 The Student receives any financial assistance, irrespective of source, to pay towards the Course of Study
        other than this loan from NSFAS.
        The student consents to NSFAS accessing financial records from institutions to establish whether the student has
        received any financial
        assistance in connection with the Course of Study other than this loan from NSFAS.</p>
      <p>4.4 The Student commits any breach of any term of this agreement.</p>
      <p>4.5 The Student’s household income exceeds the household income threshold set by NSFAS from time to time
        (currently R 600 000.00 per annum)</p>

      <p><b>5. EARLY SETTLEMENT AND PREPAYMENT BY THE STUDENT</b></p>


      <p>5.1 The Student and Surety are allowed to settle his/her debt to NSFAS at any time, without giving advance
        notice to NSFAS.</p>
      <p>5.2 The amount required to settle with NSFAS is:</p>
      <p style="padding-left: 1%;">5.2.1 The unpaid balance of the loan amount at that time, plus</p>
      <p style="padding-left: 1%;">5.2.2 The unpaid interest on the loan amount.</p>
      <p>5.3 By paying the whole of the settlement amount to NSFAS, the Student and Surety will terminate this
        agreement.</p>
      <p>5.4 The Student and Surety are entitled, at any time and without notice or penalty, to prepay any amount owing
        to
        NSFAS under this agreement, i.e. to pay that amount even though it is not yet due or payable.</p>



      <p><b>6. STATEMENTS</b></p>

      <p>6.1 NSFAS will deliver a statement to the Student twice a year.</p>
      <p>6.2 Every statement will, among other things, show:</p>
      <p style="padding-left: 1%;">6.2.1 Any payments received by NSFAS from the Student and Surety in the previous
        months.</p>
      <p style="padding-left: 1%;">6.2.2 The interest which has been charged by NSFAS over the previous months.</p>
      <p style="padding-left: 1%;">6.2.3 The unpaid balance of the loan amount and accrued interest still owed by the
        Student and Surety to NSFAS.
      </p>
      <p>6.3 NSFAS will provide the Student annually with a table that indicates the capital amount borrowed,
        interest charged, minimum amount payable and the remaining term of the loan.</p>


      <p><b>7. STUDENT WARRANTIES</b></p>

      <p><u>STUDENT OBLIGATIONS IN FIRST, SECOND AND SUBSEQUENT YEARS OF STUDY</u></p>

      <p>The Student warrants (i.e. guarantees) that:</p>


      <p>7.1 The following facts are true and complete in every aspect:</p>
      <p style="padding-left: 1%;">7.1.1 The facts recorded in this , under the heading Particulars of Student.</p>
      <p style="padding-left: 1%;">7.1.2 The facts stated by the Student in and in relation to the Student application
        for a loan from NSFAS.</p>
      <p style="padding-left: 1%;">7.1.3 The facts stated in paragraphs 1, 2 and 3 of the Loan Agreement Form </p>


      <p>7.2 The Student will always obey all the rules and codes of conduct of the educational institution and pursue
        the course of study with commitment and dedication.</p>

      <p>7.3 The Student shall inform NSFAS in writing immediately:</p>
      <p style="padding-left: 1%;">7.3.1 Whenever the Student takes up a new job, whether after a period of employment
        or otherwise,
        and at the same time supply the following information:</p>
      <p style="padding-left: 2%;">7.3.1.1 The new employer’s name, physical and postal addresses, telephone number, and
        e-mail address; and</p>
      <p style="padding-left: 2%;">7.3.1.2 The monthly or annual salary which the Student will be earning.</p>
      <p style="padding-left: 1%;">7.3.2 Whenever the address of the Student and/or the Suretychanges.</p>
      <p style="padding-left: 1%;">7.3.3 Whenever the Student salary (or other remuneration or income) increases or
        decreases.</p>
      <p style="padding-left: 1%;">7.3.4 If the educational institution refuses to register or admit the Student or
        expels or suspends the
        Student.</p>
      <p style="padding-left: 1%;">7.3.5 If the Student receives any other financial assistance (i.e. apart from the
        loan provided for
        in this agreement) in connection with the course of study.</p>
      <p style="padding-left: 1%;">7.3.6 When the Student has decided to emigrate to another country before the expiry
        of the service
        commitment period. Such a Student shall also be required to pay back the funds before leaving the country.</p>

      <p>7.4 Even if the Student circumstances have not changed in any manner mentioned in paragraph 7.3 above,
        the Student will, whenever requested by NSFAS, send the following information to NSFAS in writing:</p>
      <p style="padding-left: 1%;">7.4.1 The Student’s physical residential address.</p>
      <p style="padding-left: 1%;">7.4.2 The Student’s current telephone number and e-mail address, if applicable.</p>
      <p style="padding-left: 1%;">7.4.3 Whether the Student is still studying and, if so, what educational institution
        the Student is attending, and what course he/she is studying.</p>
      <p style="padding-left: 1%;">7.4.4 Whether the Student is employed or unemployed.</p>
      <p style="padding-left: 1%;">7.4.5 If the Student is employed, the employer’s:</p>
      <p style="padding-left: 2%;">- Name</p>
      <p style="padding-left: 2%;">- Address</p>
      <p style="padding-left: 2%;">- email address</p>
      <p style="padding-left: 2%;">- telephone number </p>
      <p style="padding-left: 2%;">- and the total monthly remuneration which the Student receives from that employer.
      </p>

      <p>7.5 The Student and Surety are not, and never have been, an unrehabilitated insolvent.</p>

      <p>7.6 The Student and Surety are not, and never have been, subject to an administration
        order in terms of section 74 of the Magistrates Courts Act.</p>

      <p>7.7 The Student and Surety have never been subject to an order of a competent court holding
        the Student to be mentally unfit.</p>


      <p><b>8. AUTHORITY</b></p>

      <p>8.1 The Student hereby authorises NSFAS at any time, and from time to time, to request and obtain any academic
        or financial information about the Student which NSFAS may require, whether from the educational institution or
        from any employer, bank or other financial institution, or from any other person, without the prior consent of
        the Student.</p>
      <p>8.2 The Student hereby consents to NSFAS obtaining consumer credit information (as defined in section 70(1) of
        the NCA)
        relating to the Student from any credit bureau for any purpose contemplated in section 70(2)(g) of the NCA.</p>


      <p><b>9. LEGAL PROCEEDINGS</b></p>

      <p>9.1 If NSFAS sues the Student and Surety to enforce payment of the loan amount and/or interest:</p>
      <p style="padding-left: 1%;">9.1.1 A certificate signed by a duly authorised officer of NSFAS, stating the fact
        that payment has
        become due, and the amount of the loan amount and interest then outstanding, will be sufficient proof
        of those facts to enable legal proceedings (including proceedings for default judgement, summary
        judgement or provisional sentence) to be instituted in any court to recover those amounts.</p>
      <p style="padding-left: 1%;">9.1.2 The Student and Surety agree that any Magistrate's Court having jurisdiction at
        the Student
        chosen service address, will have full jurisdiction in respect of such legal proceedings, although
        the amount of NSFAS's claim may exceed the normal limits of that court jurisdiction.</p>
      <p style="padding-left: 1%;">9.1.3 The Student and Surety will be responsible for all legal costs incurred by
        NSFAS, namely:</p>
      <p style="padding-left: 2%;">9.1.3.1 The fees charged by NSFAS's attorneys, on an attorney and own client basis,
        in accordance
        with the non-litigious tariff prescribed from time to time by the Cape Law Society.</p>
      <p style="padding-left: 2%;">9.1.3.2 All disbursements incurred by NSFAS attorneys.</p>
      <p style="padding-left: 2%;">9.1.3.3 Collection commission at the prescribed rate [presently Ten (10) per cent]
        on all amounts collected from the Student by NSFAS's attorneys.</p>

      <p>9.2 The Student acknowledges that, if the Student is earning remuneration sufficient
        to oblige the Student to begin making repayments to NSFAS, or to oblige the Student
        to increase the number of regular repayments already being made, and fails to notify
        NSFAS of this in any manner provided for in this agreement:</p>
      <p style="padding-left: 1%;">9.2.1 Although the Student will then be in breach of this agreement, entitling
        NSFAS to the various remedies contemplated in it, the Student will not be in default
        for the purposes of section 103(5) of the NCA.</p>
      <p style="padding-left: 1%;">9.2.2 Accordingly, until NSFAS becomes aware of the Student’s breach, the period
        that a consumer is in default as contemplated in that section 103(5), will not commence.</p>
      <p style="padding-left: 1%;">9.2.3 The Student will be afforded the protection of that section, which limits the
        amount
        of interest which may accrue while the Student is in default, only after NSFAS is informed that the Student is
        in default.</p>



      <p><b>10. DEFAULT AND CREDIT BUREAUS</b></p>

      <p>10.1 The Student will be in default of this agreement if the Student and Surety do not pay any amount payable
        to NSFAS
        in terms of this agreement or make any material incorrect or false statements or representations or ommissions
        to NSFAS
        in order to secure this loan or brings NSFAS into disrepute.</p>
      <p>10.2 If the Student or Surety fail to make repayments to NSFAS in the manner provided for in this agreement,
        NSFAS may place the names of the Student and Surety on a list of defaulting debtors maintained and published by
        any credit bureau, i.e. to submit adverse information concerning the Student to a credit bureau.</p>

      <p>10.3 If the Student fails to notify NSFAS in the manner and at the intervals referred to in paragraph
        7.4 above, the Student will be deemed to be failing to make repayments as required by this agreement,
        and NSFAS will be entitled to submit adverse information concerning the Student to a credit bureau.</p>

      <p>10.4 NSFAS must give the Student and Surety Twenty (20) working days written notice of NSFAS's intention
        to submit adverse information concerning the Student and Surety to a credit bureau.</p>

      <p>10.5 NFAS will give the Student Twenty (20) working days from the date of the letter to commence making
        repayments,
        to resume making repayments, or to notify NSFAS in the manner contemplated in paragraph 7.4.</p>

      <p>10.6 The Student and Surety agree that the period of Twenty (20) working days referred to in paragraph 10.5
        will afford the Student and Surety a reasonable opportunity (as contemplated in section 21 (4)(b)
        of the NSFAS Act) to begin or resume making repayments, or to notify NSFAS as required by paragraph 7.4.</p>

      <p>10.7 The credit bureau to which NSFAS will send default information concerning the Student and Surety (if
        applicable)
        is the Information Trust Corporation. The current contact details of that bureau are:</p>
      <p style="padding-left: 1%;">10.7.1 Telephone number: 011 214 6901/0861 482 4357</p>
      <p style="padding-left: 1%;">10.7.2 Fax number: 011 214 6001</p>
      <p style="padding-left: 1%;">10.7.3 Website: www.transunion.co.za</p>

      <p>10.8 The business of credit bureaus is to provide, to fee-paying clients, credit profiles
        and credit worthiness scores on the people about whom they keep credit-related information.</p>

      <p>10.9 The Student and Surety are entitled to contact the credit bureau:</p>
      <p style="padding-left: 1%;">10.9.1 To which NSFAS refers information concerning defaulting borrowers.</p>
      <p style="padding-left: 1%;">10.9.2 To request the credit bureau to disclose to the Student and Surety any
        information which NSFAS has sent to the bureau concerning the Student and Surety.</p>
      <p style="padding-left: 1%;">10.9.3 To rectify any incorrect information kept or published by the bureau
        concerning the Student and Surety.
      </p>

      <p>10.10 If the Student is employed and is obliged to, but fails to make repayments
        in terms of this agreement, a further option available to NSFAS is to compel the Student’s employer
        (in terms of Section 23 of the NSFAS Act) to make deductions from the Student’s remuneration,
        and pay the deductions over to NSFAS.</p>

      <p>10.11 Another option available to NSFAS if the Student and Surety fail to make repayments
        in terms of this agreement, is to commence legal proceedings against the Student, as envisaged in paragraph 9.
      </p>

      <p><b>11. CESSION</b></p>

      <p>The Student and Surety will not be entitled to cede, assign, transfer or delegate any of his/her rights or
        obligations under this agreement without the prior written consent of NSFAS. However, NSFAS will be entitled
        in its absolute discretion and at any time to cede, assign, transfer or delegate any or all its rights or
        obligations under this agreement to any natural or juristic person of its choice.</p>


      <p><b>12. FORMALITIES</b></p>

      <p>12.1 The Loan Agreement between NSFAS, the Student and the Surety is made up of the following documents:</p>
      <p style="padding-left: 1%;">12.1.1 The loan terms and conditions in this document</p>


      <p><b>13. TERMINATION OF THIS AGREEMENT BY NSFAS</b></p>

      <p>13.1 In terms of section 123 of the NCA:</p>
      <p style="padding-left: 1%;">13.1.1 NSFAS is allowed to terminate this agreement if the Student and Surety do not
        comply with it.</p>
      <p style="padding-left: 1%;">13.1.2 If NSFAS did want to terminate this agreement, it would have to take the steps
        set out in Part C of Chapter 6 of the Act.</p>
      <p>13.2 However, NSFAS will not terminate this agreement. If the Student and the Surety do not
        comply with this agreement, NSFAS will enforce its rights in terms of the agreement, rather than terminate it.
      </p>


      <p><b>14. ADDRESSES FOR RECEIVING DOCUMENTS</b></p>

      <p>14.1 Any statement or notice referred to in this agreement may be sent to the Student:</p>
      <p style="padding-left: 1%;">14.1.1 By posting it to the home address recorded in paragraph 1 of the Loan
        Agreement Form; or</p>
      <p style="padding-left: 1%;">14.1.2 By sending it to the Student e-mail address recorded in paragraph 1 of the
        Loan Agreement Form (if applicable); or</p>
      <p style="padding-left: 1%;">14.1.3 By sending it by SMS to the Student cellphone number recorded in paragraph 1
        of the Loan Agreement Form (if applicable).</p>

      <p>14.2 The Student chooses the residential home address recorded in paragraph 1 of the Loan Agreement Form as the
        address at
        which the Student will accept delivery of all pleadings or other legal processes in connection with this
        agreement.</p>

      <p>14.3 The Student may deliver any notices or correspondence to NSFAS in terms of or relating to this agreement
        to
        physical address or e-mail addresses recorded under “Particulars of Credit Provider” on the front page of this
        document.</p>

      <p>14.4 The Student may change his/her cellphone number or home, postal or e-mail addresses by sending a notice to
        NSFAS by registered post, fax or e-mail.</p>

      <p>14.5 NSFAS may change its contact by sending a notice to the Student in any way permitted by paragraph 14.1.
      </p>

      <p><b>15. STATUTORY INFORMATION</b></p>

      <p>On 31 May 2006 regulations were promulgated in terms of the NCA in the Government Gazette No. 28864.
        In this agreement those regulations (as amended or replaced from time to time) will be referred to as
        The Regulations. They oblige NSFAS to provide certain information to the Student:</p>

      <p>15.1 Complaint to the National Credit Regulator ("The Regulator")</p>
      <p style="padding-left: 1%;">15.1.1 If the Student believes that NSFAS has contravened the NCA, the Student may
        submit a complaint to the
        Regulator.</p>
      <p style="padding-left: 1%;">15.1.2 Any such complaint must be submitted in the form and manner referred to in
        Regulation 50.</p>
      <p>15.2 Alternative Dispute Resolution. As an alternative to submitting a complaint to the Regulator, the Student
        may refer an alleged contravention of the NCAby NSFAS to either:</p>
      <p style="padding-left: 1%;">15.2.1 A consumer court as defined in section 1 of the NCA; or</p>
      <p style="padding-left: 1%;">15.2.2 An alternative dispute resolution agent as contemplated in section
        134(1)(b)(ii) of the NCA, for
        conciliation, mediation or arbitration.</p>

      <p>15.3 Application to Tribunal. If the Student does not succeed, by way of the alternative
        dispute mechanism referred to in paragraph 15.2, in resolving any dispute with NSFAS concerning an
        alleged contravention of the NCA, the Student may apply for appropriate relief to the
        National Consumer Tribunal established in terms of section 26 of the NCA.</p>

      <p>15.4 Over-indebtedness.</p>
      <p style="padding-left: 1%;">15.4.1 The Student is entitled to apply to a debt counsellor to be declared
        over-indebted.</p>
      <p style="padding-left: 1%;">15.4.2 Any such application must be made in the manner set out in Regulation 24 of
        the NCA.</p>

      <p>15.5 Contact Details of the National Credit Regulator.</p>
      <p style="padding-left: 2%;">Telephone: 0860 627 627</p>
      <p style="padding-left: 2%;">Website: <a href="http://www.ncr.org.za" target="_blank">www.ncr.org.za</a></p>

      <p><b>16. INDEMNITY AND LIMITATION OF LIABILITY</b></p>

      <p>NSFAS shall, under no circumstances, be liable for any general damages, special damages and/or consequential
        damages, whether such damages are caused by negligence or any fault or deliberate action of NSFAS and/or
        its employees. Furthermore, in the event that financial aid is extended to any person based on inaccurate,
        incomplete and/or outdated information, NSFAS reserves the right to cancel such funding with immediate effect
        and claim all funds already disbursed based on such inaccurate and/or incomplete and/or outdated information.
      </p>


      <p><b>17. PROVISION OF INFORMATION</b></p>

      <p>The Student and Surety agree to provide NSFAS with true, accurate and complete information and documentation
        as required for purposes of this contract. NSFAS is entitled to make due inquiries relating to the
        information provided in order to ascertain the validity and veracity thereof but is not obliged to do so.</p>


      <p><b>18. DATA PROTECTION</b></p>

      <p>NSFAS undertakes to ensure that it respects and maintains the privacy and confidentiality of any personal
        information and data that it may obtain or gain access to and in particular undertakes that it will not
        process any of the Student and Surety’s personal information without his/her prior written consent.</p>


      <p><b>19. BREACH AND TERMINATION </b></p>

      <p>If the Student and Surety breach any of the terms of this contract, then NSFAS will be entitled,
        at its option to cancel this agreement and claim back from the Student and Surety any monies paid to the
        Student.</p>

      <p><b>20. NOTICES AND DOMICILIA </b></p>

      <p>Written notices will be sent to the Student and Surety at the street address provided
        on the Student application form. Legal notices and court proceedings may also be served
        on the Student and Surety at this address.</p>

      <p><b>21. APPLICABLE LAW AND JURISDICTION</b></p>

      <p>This contract will in all respects be governed by and construed under the laws of the Republic of South Africa.
        The parties hereby consent and submit to the non-exclusive jurisdiction of the Western Cape High Court,
        Cape Town in any dispute arising from or in connection with this Agreement.</p>


      <p><b>22. MISCELLANEOUS MATTERS</b></p>

      <p>This contract constitutes the whole of the agreement between the parties relating to the matters
        dealt with herein and, save to the extent otherwise provided herein, no undertaking,
        representation, term or condition relating to the subject matter of this contract not
        incorporated in this contract shall be binding on either of the parties.</p>

      <p><b>23. FUNDER SPECIFIC TERMS & CONDITIONS</b></p>

      <p>23.1 This contract constitutes the whole of the agreement between the parties relating to the matters dealt
        with herein and, save to the extent otherwise provided herein, no undertaking, representation,
        term or condition relating to the subject matter of this contract not incorporated in this contract
        shall be binding on either of the parties.</p>

      <p>23.2 The Student agrees and understands that certain provisions within this agreement may be amended by NSFAS
        from time to time as the need arises, which changes will be communicated to the Student via the NSFAS website
        or MYNSFAS portal or email or sms or through circulars addressed to the Student’s educational institution.</p>

      <span (click)="readLess()" style="color:#AE190A"><u>Read Less</u></span>

    </div>


    <div class="checkbox">
      <div class="checkbox checkbox-fill">
        <input type="checkbox" name="checkbox-fill-2" id="checkbox-fill-2" name="agree" required [(ngModel)]="loanTC" />
        <label for="checkbox-fill-2" class="cr">I have read the full Loan Terms and conditions.
          <span class="text-end">
            <!-- <a
          style="color: #AE190A"
          routerLink="/nba"
          target="_blank"
          >Download</a
        > -->
            <a href="/assets/LoanTsandCs.pdf" style="color: #AE190A" target="_blank">Download</a>
          </span></label> <br><br>

        <!--div class="checkbox checkbox-fill">
          <input type="checkbox" name="checkbox-fill-3" id="checkbox-fill-3" name="agree" [(ngModel)]="tvetTC"
            required />
          <label for="checkbox-fill-3" class="cr">I have read the full TVET Terms and conditions.
            <span class="text-end">
          <a style="color: #AE190A"
        routerLink="/nba"
        target="_blank"
        >Download</a>
              <a href="/assets/Tvet.pdf" style="color: #AE190A" target="_blank">Download</a>
            </span></label>

          
        </div-->
        <div class="invalid-feedback">
          Please agree to the terms and conditions
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="d-grid gap-2">
          <button class="btn btn-outline-dark btn-md mb-3" type="button" (click)="close()">Decline</button>
        </div>
      </div>
      <div class="col-md-4">
        <div class="d-grid gap-2">
          <button class="btn btn-primary btn-md mb-3" type="submit" [disabled]=" !loanTC"
            (click)=" openApply()">Accept</button>
        </div>
      </div>
    </div>
  </div>
</app-card>