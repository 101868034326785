<div class="row justify-content-center">
  <div class="col-md-5">

    <h2 class="mb-3 text-center">NSFAS funding application</h2>

    <form (ngSubmit)="onSubmit()" #documentForm="ngForm" class="px-5 needs-validation" [ngClass]="validated===true?'was-validated':''">

      <h4 style="text-align: center;">Kindly upload following documents</h4>
      <br/>
      <p class="text-danger"><small>If you are prompted to upload an NSFAS Consent Form more than once, please upload the SAME form.
        The NSFAS Consent Form must be completed and signed  by all relevant parties.</small></p>

      <br/>

      <h4 style="text-align: center;" *ngIf="applicationsService.application.spouse && applicationsService.application.spouse.requiredDocuments && applicationsService.application.spouse.requiredDocuments.length > 0">Spouse's documents</h4>
      <div *ngIf="applicationsService.application.spouse && applicationsService.application.spouse.requiredDocuments">
        <div class="row justify-content-center" *ngFor="let requiredDocument of applicationsService.application.spouse.requiredDocuments; let i = index">
          <div class="col-md-12 text-start">
            <div class="mb-5 mt-2">
              <div class="mb-3">
                <label for="formFileSm" class="form-label">{{requiredDocument.caption}}</label>
                <input required [(ngModel)]="applicationsService.application.spouse.requiredDocuments[i]" appFileUpload [file]="applicationsService.application.spouse.requiredDocuments[i]" name="spouseRequiredDocument{{i}}" accept=".jpeg,.docx,.pdf" class="form-control form-control-sm" type="file">
                <div class="invalid-feedback">
                  {{applicationsService.application.spouse.requiredDocuments[i].name}} is required
                </div>
              </div>
              <div class="alert alert-light text-start text-dark" *ngIf="requiredDocument.documentType ==='SARS_CONSENT_FORM'">
                <small>Please complete the spouse section of the form. The form requires your signature and your spouse's signature. Thereafter, upload.
                </small>
                <a href="/assets/NSFAS-2024-Applications-Consent-Form.pdf" target="_blank">{{requiredDocument.caption}}</a>
              </div>
              <small class="ps-3" *ngIf="!applicationsService.application.spouse.requiredDocuments[i]">PDF or Jpeg, max 20mb</small>
              <small class="ps-3" *ngIf="applicationsService.application.spouse.requiredDocuments[i]">{{applicationsService.application.spouse.requiredDocuments[i].name}}</small>
            </div>
          </div>
        </div>
      </div>

      <h4 style="text-align: center;" *ngIf="applicationsService.application.father && applicationsService.application.father.requiredDocuments && applicationsService.application.father.requiredDocuments.length > 0">Father's documents</h4>

      <div *ngIf="applicationsService.application.father && applicationsService.application.father.requiredDocuments">
        <div class="row justify-content-center" *ngFor="let requiredDocument of applicationsService.application.father.requiredDocuments; let i = index">
          <div class="col-md-12 text-start">
            <div class="mb-5 mt-2">
              <div class="mb-3">
                <label for="formFileSm" class="form-label">{{requiredDocument.caption}}</label>
                <input required [(ngModel)]="applicationsService.application.father.requiredDocuments[i]" appFileUpload [file]="applicationsService.application.father.requiredDocuments[i]" name="fatherRequiredDocument{{i}}" accept=".jpeg,.docx,.pdf" class="form-control form-control-sm" type="file">
                <div class="invalid-feedback">
                  {{applicationsService.application.father.requiredDocuments[i].name}} is required
                </div>
              </div>
              <div class="alert alert-light text-start text-dark" *ngIf="requiredDocument.documentType ==='SARS_CONSENT_FORM'">
                <small>Please complete the father section of the form. The form requires your signature and your father's signature. Thereafter, upload.
                </small>
                <a href="/assets/NSFAS-2024-Applications-Consent-Form.pdf" target="_blank">{{requiredDocument.caption}}</a>
              </div>
              <small class="ps-3" *ngIf="!applicationsService.application.father.requiredDocuments[i]">PDF or Jpeg, max 20mb</small>
              <small class="ps-3" *ngIf="applicationsService.application.father.requiredDocuments[i]">{{applicationsService.application.father.requiredDocuments[i].name}}</small>
            </div>
          </div>
        </div>
      </div>

      <h4 style="text-align: center;" *ngIf="applicationsService.application.mother && applicationsService.application.mother.requiredDocuments && applicationsService.application.mother.requiredDocuments.length> 0">Mother's documents</h4>

      <div *ngIf="applicationsService.application.mother && applicationsService.application.mother.requiredDocuments">
        <div class="row justify-content-center" *ngFor="let requiredDocument of applicationsService.application.mother.requiredDocuments; let i = index">
          <div class="col-md-12 text-start">
            <div class="mb-5 mt-2">
              <div class="mb-3">
                <label for="formFileSm" class="form-label">{{requiredDocument.caption}}</label>
                <input required [(ngModel)]="applicationsService.application.mother.requiredDocuments[i]" appFileUpload [file]="applicationsService.application.mother.requiredDocuments[i]" name="motherRequiredDocument{{i}}" accept=".jpeg,.docx,.pdf" class="form-control form-control-sm" type="file">
                <div class="invalid-feedback">
                  {{applicationsService.application.mother.requiredDocuments[i].name}} is required
                </div>
              </div>
              <div class="alert alert-light text-start text-dark" *ngIf="requiredDocument.documentType ==='SARS_CONSENT_FORM'">
                <small>Please complete the mother section of the form. The form requires your signature and your mother's signature. Thereafter, upload.
                </small>
                <a href="/assets/NSFAS-2024-Applications-Consent-Form.pdf" target="_blank">{{requiredDocument.caption}}</a>
              </div>
              <small class="ps-3" *ngIf="!applicationsService.application.mother.requiredDocuments[i]">PDF or Jpeg, max 20mb</small>
              <small class="ps-3" *ngIf="applicationsService.application.mother.requiredDocuments[i]">{{applicationsService.application.mother.requiredDocuments[i].name}}</small>
            </div>
          </div>
        </div>
      </div>

      <h4 style="text-align: center;" *ngIf="applicationsService.application.guardian && applicationsService.application.guardian.requiredDocuments && applicationsService.application.guardian.requiredDocuments.length > 0">Guardian's documents</h4>

      <div *ngIf="applicationsService.application.guardian && applicationsService.application.guardian.requiredDocuments">
        <div class="row justify-content-center" *ngFor="let requiredDocument of applicationsService.application.guardian.requiredDocuments; let i = index">
          <div class="col-md-12 text-start">
            <div class="mb-5 mt-2">
              <div class="mb-3">
                <label for="formFileSm" class="form-label">{{requiredDocument.caption}}</label>
                <input required [(ngModel)]="applicationsService.application.guardian.requiredDocuments[i]" appFileUpload name="guardianRequiredDocument{{i}}" [file]="applicationsService.application.guardian.requiredDocuments[i]" accept=".jpeg,.docx,.pdf" class="form-control form-control-sm" type="file">
                <div class="invalid-feedback">
                  {{applicationsService.application.guardian.requiredDocuments[i].name}} is required
                </div>
              </div>
              <div class="alert alert-light text-start text-dark" *ngIf="requiredDocument.documentType ==='SARS_CONSENT_FORM'">
                <small>Please complete the guardian section of the form. The form requires your signature and your guardian's signature. Thereafter, upload.
                </small>
                <a href="/assets/NSFAS-2024-Applications-Consent-Form.pdf" target="_blank">{{requiredDocument.caption}}</a>
              </div>
              <small class="ps-3" *ngIf="!applicationsService.application.guardian.requiredDocuments[i]">PDF or Jpeg, max 20mb</small>
              <small class="ps-3" *ngIf="applicationsService.application.guardian.requiredDocuments[i]">{{applicationsService.application.guardian.requiredDocuments[i].name}}</small>
            </div>
          </div>
        </div>
      </div>


      <h4 style="text-align: center;" *ngIf="applicationsService.application.disabilityAnnexure || applicationsService.application.provisionalOfferLetter || applicationsService.application.vulnerableChildOrNonSassDeclaration || applicationsService.application.consentForm">Applicant's documents</h4>
      <div class="row justify-content-center" *ngIf="applicationsService.application.disabilityAnnexure">
        <div class="col-md-12 text-start">
          <div class="mb-5 mt-2">
            <div class="mb-3">
              <label for="formFileSm" class="form-label">{{applicationsService.application.disabilityAnnexure.caption}}</label>
              <input required [(ngModel)]="applicationsService.application.disabilityAnnexure" appFileUpload   name="disabilityAnnexure" accept=".jpeg,.docx,.pdf" class="form-control form-control-sm" id="formFileSm" type="file">
              <a href="/assets/NSFAS%20Disability%20Annexure%20A_2023.pdf" target="_blank">Download Disability Annexure</a>
              <div class="invalid-feedback">
                {{applicationsService.application.disabilityAnnexure.caption}} is required
              </div>
            </div>
            <small class="ps-3" *ngIf="!applicationsService.application.disabilityAnnexure">PDF or Jpeg, max 20mb</small>
            <small class="ps-3" *ngIf="applicationsService.application.disabilityAnnexure">{{applicationsService.application.disabilityAnnexure.name}}</small>
          </div>
        </div>
      </div>

      <!-- <div class="row justify-content-center" *ngFor="let docs in applicationsService.application?.requiredDocuments">
        <div class="col-md-12 text-start" *ngIf="docs.documentType =='SARS_CONSENT_FORM' && !applicationsService.application.sassaFunded">
          <div class="mb-5 mt-2">
            <div class="mb-3">
              <label for="formFileSm" class="form-label">{{applicationsService.application.consentForm?.caption}}</label>
              <input required [(ngModel)]="applicationsService.application.consentForm" appFileUpload   name="sarsConsent" accept=".jpeg,.docx,.pdf" class="form-control form-control-sm" id="formFileSm" type="file">
              <div class="invalid-feedback">
                {{applicationsService.application.consentForm?.caption}} is required Correct
              </div>
            </div>
            <small class="ps-3" *ngIf="!applicationsService.application.consentForm">PDF or Jpeg, max 20mb</small>
            <small class="ps-3" *ngIf="applicationsService.application.consentForm">{{applicationsService.application.consentForm?.name}}</small>
          </div>
        </div>
      </div> -->
      <div class="row justify-content-center" *ngIf="!applicationsService.application.sassaFunded && applicationsService.application?.consentForm">
        <div class="col-md-12 text-start">
          <div class="mb-5 mt-2">
            <div class="mb-3">
              <label for="formFileSm" class="form-label">{{applicationsService.application.consentForm?.caption}}</label>
              <input required [(ngModel)]="applicationsService.application.consentForm" appFileUpload   name="sarsConsent" accept=".jpeg,.docx,.pdf" class="form-control form-control-sm" id="formFileSm" type="file">
              <div class="invalid-feedback">
                {{applicationsService.application.consentForm?.caption}} is required Incorrect
              </div>
            </div>
            <small class="ps-3" *ngIf="!applicationsService.application.consentForm">PDF or Jpeg, max 20mb</small>
            <small class="ps-3" *ngIf="applicationsService.application.consentForm">{{applicationsService.application.consentForm?.name}}</small>
          </div>
        </div>
      </div>

      <div class="row justify-content-center" *ngIf="applicationsService.application.vulnerableChildOrNonSassDeclaration">
        <div class="col-md-12 text-start">
          <div class="mb-5 mt-2">
            <div class="mb-3">
              <label for="formFileSm" class="form-label">{{applicationsService.application.vulnerableChildOrNonSassDeclaration.caption}}</label>
              <input required [(ngModel)]="applicationsService.application.vulnerableChildOrNonSassDeclaration" appFileUpload   name="vulnerableChildOrNonSassDeclaration" accept=".jpeg,.docx,.pdf" class="form-control form-control-sm" id="vulnerableChildOrNonSassDeclaration" type="file">
              <div class="invalid-feedback">
                {{applicationsService.application.vulnerableChildOrNonSassDeclaration.caption}} is required
              </div>
            </div>
            <small class="ps-3" *ngIf="!applicationsService.application.vulnerableChildOrNonSassDeclaration">PDF or Jpeg, max 20mb</small>
            <small class="ps-3" *ngIf="applicationsService.application.vulnerableChildOrNonSassDeclaration">{{applicationsService.application.vulnerableChildOrNonSassDeclaration.name}}</small>
          </div>
        </div>
      </div>

      <div class="row justify-content-center" *ngIf="applicationsService.application.provisionalOfferLetter">
        <div class="col-md-12 text-start">
          <div class="mb-5 mt-2">
            <div class="mb-3">
              <label for="formFileSm" class="form-label">{{applicationsService.application.provisionalOfferLetter.caption}}</label>
              <input required [(ngModel)]="applicationsService.application.provisionalOfferLetter" appFileUpload   name="provisionalOfferLetter" accept=".jpeg,.docx,.pdf" class="form-control form-control-sm" id="provisionalOfferLetter" type="file">
              <div class="invalid-feedback">
                {{applicationsService.application.provisionalOfferLetter.caption}} is required
              </div>
            </div>
            <small class="ps-3" *ngIf="!applicationsService.application.provisionalOfferLetter">PDF or Jpeg, max 20mb</small>
            <small class="ps-3" *ngIf="applicationsService.application.provisionalOfferLetter">{{applicationsService.application.provisionalOfferLetter.name}}</small>
          </div>
        </div>
      </div>
  <div class="row">
    <div class="col-md-6">
      <div class="d-grid gap-2">

        <button class="btn btn-outline-dark btn-lg mb-3" type="button" (click)="previous()">Previous</button>
      </div>

    </div>
    <div class="col-md-6">
      <div class="d-grid gap-2">

        <button class="btn btn-primary btn-lg mb-3" type="submit" >Next</button>
      </div>

    </div>
  </div>
  </form>

</div>

</div>

